<template>
  <div>
    <vs-popup title="Filter" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Dari Tanggal:</label>
            <flat-pickr class="w-full" v-model="data.fromDate"></flat-pickr>
          </div>
          <div class="vx-col sm:w-1/2 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Sampai Tanggal:</label>
            <flat-pickr class="w-full" v-model="data.toDate"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Proyek</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" color="danger" type="border" @click="clearFilter" :disabled="isEmptyFilter">Clear Filter</vs-button>
            <vs-button class="mr-3" color="primary" @click="filter" :disabled="isEmptyFilter">Filter</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'SerahTerimaCustomerFilter',
  props: ['isActive'],
  components: {
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  computed: {
    isEmptyFilter () {
      return _.isEmpty(this.data)
    }
  },
  data () {
    return {
      errors: null,
      data: {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      },
      proyeks: []
    }
  },
  methods: {
    filter () {
      this.$emit('filter', this.data)
      this.emitModalIsActive(false)
    },

    clearFilter () {
      this.data = {}
      this.$emit('filter', {})
      this.emitModalIsActive(false)
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
